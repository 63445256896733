<template>
  <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="1.33309" height="1.4" fill="currentColor"/>
    <rect y="2.7998" width="1.33309" height="1.4" fill="currentColor"/>
    <rect y="5.6001" width="1.33309" height="1.4" fill="currentColor"/>
    <rect x="3" width="1.33309" height="1.4" fill="currentColor"/>
    <rect x="3" y="2.7998" width="1.33309" height="1.4" fill="currentColor"/>
    <rect x="3" y="5.6001" width="1.33309" height="1.4" fill="currentColor"/>
    <rect x="6" width="1.33309" height="1.4" fill="currentColor"/>
    <rect x="6" y="2.7998" width="1.33309" height="1.4" fill="currentColor"/>
    <rect x="6" y="5.6001" width="1.33309" height="1.4" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: "IconMove3x3"
}
</script>

